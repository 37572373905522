/* @import 'react-redux-toastr/src/styles/index'; */
.feed__menu {
  position: relative;
  z-index: 2 !important;
}
.Type__menu {
  position: relative;
  z-index: 2 !important;
}
.Feed__menu {
  position: relative;
  z-index: 2 !important;
}
.Geo__menu {
  position: relative;
  z-index: 2 !important;
}

.MuiTableCell-alignLeft {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.Mui-active {
  color: black !important;
}
.MuiTableSortLabel-icon {
  color: black !important;
}
#react-select-3-listbox,
#react-select-5-listbox {
  z-index: 99999;
}

.cancel-btn {
  background-color: #8f8f8f !important;
  color: #ffffff;
  /* margin-left: 16px !important; */
  box-shadow: none !important;
}
.cancel-btn:hover {
  background-color: #707070 !important;
}
.action {
  display: flex;
  align-items: center;
}
.action .action_btn {
  margin-left: 20px;
}

.gridpadding {
  padding: 10px 30px 0px 30px;
}

.spacing {
  margin-bottom: 15px !important;
}
.queries_headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.bordering {
  border-radius: 8px !important;
  border-right-color: initial !important;
}

@media screen and (max-width: 480px) {
  .action {
    display: block;
  }
  .action .action_btn {
    margin-left: 0px;
    margin-top: 16px;
  }
  /* .css-156h45u{
    width: 100% !important;
  } */
}

.dense_table > tbody > tr > td:first-of-type {
  max-width: 300px;
  overflow-wrap: break-word;
}

.dense_table > tbody > tr > td:last-of-type {
  padding-right: 6px;
}

.falashmou {
  margin-top: 1rem !important;
  float: right !important;
  width: 100% !important;
}

.falashmou div > h3 {
  margin-bottom: 10px;
}

.falashmou > * {
  min-height: 250px;
  padding: 25px;
}
.p_cards {
  width: 100%;
  max-width: 500px;
}
.p_cards:last-of-type {
  margin-left: 5%;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.pagination_button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.select_size {
  width: 274px;
  border-radius: 8px;
}
