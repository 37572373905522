.container {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.img_container {
  grid-column: span 1;
  display: flex;
  align-items: center;
}
.img_container h1 {
  padding-left: 10%;
  padding-bottom: 5%;
}
.card{
    display: flex;
    align-items: center;
}
.form_container {
  grid-column: span 2;
  display: flex;
  align-items: center;
}
.form {
  max-width: 600px;
  padding: 50px;
  margin: auto;
}

@media only screen and (max-width: 1000px) {
  .card {
    display: none;
  }
  .form_container {
    grid-column: span 3;
  }
}
